.button {
  padding: 0;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  margin-right: 0;
  font-size: 0;
  opacity: 1;
}

.button:hover {
  opacity: 0.85;
  transition: 0.5s;
  cursor: pointer;
}
