.button_type_edit {
  min-width: 24px;
  min-height: 24px;
  border: 1px solid #fff;
  justify-self: end;
  align-self: end;
  background-image: url('../../../images/Edit.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 41.66%;
}

@media screen and (max-width: 540px) {
  .button_type_edit {
    min-width: 18px;
    min-height: 18px;
    margin-bottom: 3px;
  }
}