.form__title {
  text-align: center;
  margin: 0;
  margin-top: 60px;
  font-size: 24px;
  line-height: 29.05px;
}

@media screen and (max-width: 540px) {
  .form__title {
    font-size: 20px;
    line-height: 24px;
    margin-top: 40px;
  }
}
