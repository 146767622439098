.form__set {
  margin: 0;
  padding: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
  border: 0;
  position: relative;
}

.form__set:nth-child(2) {
  margin-top: 30px;
}