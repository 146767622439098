.profile__space-between {
  width: 100%;
  display: flex;
  gap: 30px;
  flex-shrink: 1;
  /* justify-content: space-between;
  align-items: center; */
}

@media screen and (max-width: 540px) {
  .profile__space-between {
    flex-direction: column;
    align-items: center;
    gap: 22px;
  }
}