.button_type_add {
  min-width: 150px;
  min-height: 50px;
  border: 2px solid #fff;
  border-radius: 2px;
  background-image: url('../../../images/add-button.svg');
  background-repeat: no-repeat;
  background-position: center center;
  flex-shrink: 0;
}

@media screen and (max-width: 540px) {
  .button_type_add {
    min-width: 282px;
    justify-self: center;
    margin-top: 33px;
    background-size: 16px;
  }
}