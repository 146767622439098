:root {
  background-color: #000;
}
.page {
  background: #000;
  min-width: 320px;
  max-width: 1280px;
  padding: 45px 200px 60px;
  box-sizing: border-box;
  color: #fff;
  font-family: "Inter", Arial, sans-serif;
  font-weight: 400;
  margin: 0 auto;
}

@media screen and (max-width: 1120px) {
  .page {
    padding: 45px 100px 60px;
  }
}

@media screen and (max-width: 970px) {
  .page {
    padding: 28px 50px 36px;
  }
}

@media screen and (max-width: 540px) {
  .page {
    padding: 0 0 36px;
    margin-top: 28px;
  }
}
