.button_type_avatar {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  border-width: 0;
  position: absolute;
  z-index: 2;
  background-image: url('../../../images/Vector.svg');
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0;
}

.button_type_avatar:hover {
  opacity: 1;
  transition: .5s;
  cursor: pointer;
}