.popup__title {
  max-height: 23px;
  margin: 0;
  margin-bottom: 54px;
  color: #000;
  font-size: 24px;
  font-weight: 900;
  line-height: 29px;
  text-align: center;
}

@media screen and (max-width: 540px) {
  .popup__title {
    max-height: 22px;
    margin-bottom: 74px;
    font-size: 18px;
    line-height: 22px;
    /* text-align: center; */
  }
}
