.popup__container-info {
  max-width: 430px;
  width: 100%;
  background-color: #fff;
  padding: 60px 36px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 32px;
  border-radius: 10px;
  position: relative;
}

@media screen and (max-width: 540px) {
  .popup__container-info {
    max-width: 280px;
    width: 100%;
    padding: 50px 18px;
  }
}
