.button_type_like {
  border: 0;
  min-width: 22px;
  height: 19px;
  /* margin-top: 5px; */
  /* margin-right: 5px; */
  background-image: url('../../../images/like.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.button_type_like:hover {
  opacity: 0.5;
}