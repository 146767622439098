.header__link {
  font-size: 18px;
  line-height: 22px;
}

@media screen and (max-width: 540px) {
  .header__link {
    font-size: 14px;
    line-height: 17px;
    margin-right: 30px;
  }
}
