.profile__name {
  max-width: calc(100vw - 300px - 400px - 24px - 18px - 30px); 
                /* width - два размера ячеек flex - padding - размер иконки edit - gap margin-right  */
  font-size: 42px;
  line-height: 114%;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
}

@media screen and (min-width: 1280px) {
  .profile__name {
    max-width: calc(1080px - 300px - 200px - 24px - 18px - 30px); 
  }
}

@media screen and (max-width: 1120px) {
  .profile__name {
    max-width: calc(100vw - 300px - 200px - 24px - 18px - 30px); 
  }
}

@media screen and (max-width: 970px) {
  .profile__name {
    max-width: calc(100vw - 300px - 100px - 24px - 10px - 30px);
    font-size: 36px;
  }
}

@media screen and (max-width: 540px) {
  .profile__name {
    max-width: calc(100vw - 38px - 18px - 10px - 28px);
    font-size: 27px;
    line-height: 33px;
    justify-items: center;
  }
}