.form__input {
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  max-height: 14px;
  padding: 0;
  padding-bottom: 11.35px;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.form__input:nth-child(2) {
  margin-top: 30px;
}
