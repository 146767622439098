.button_type_remove {
  border: 0;
  width: 18px;
  height: 19px;
  background-image: url('../../../images/Trash.svg');
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 20px;
  right: 20px;
  display: none;
}