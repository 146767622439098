.header {
  min-height: 74px;
  border-bottom: 1px solid rgba(84, 84, 84, 0.7);
  margin-bottom: 40px;
}

@media screen and (max-width: 540px) {
  .header {
    min-height: 56px;
    margin-bottom: 42px;
  }
}
