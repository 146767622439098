.header__logo {
  max-width: 142px;
  max-height: 33px;
}

@media screen and (max-width: 540px) {
  .header__logo {
    max-width: 103px;
    max-height: 24px;
    margin-left: 27px;
  }
}