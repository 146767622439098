.header__menu {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0 40px 0;
  gap: 18px;
  font-size: 18px;
  line-height: 22px;
  border-bottom: 1px solid rgba(84, 84, 84, 0.7);
  display: none;
}
