.button_type_close {
  position: absolute;
  top: -40px;
  right: -40px;
  border: 0;
  width: 32px;
  height: 32px;
  background-image: url('../../../images/Close-Icon.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media screen and (max-width: 540px) {
  .button_type_close {
    top: -36px;
    right: 0;
    width: 20px;
    height: 20px;
  }
}