.popup__container {
  max-width: 430px;
  width: 100%;
  background-color: #fff;
  padding: 34px 36px 37px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  position: relative;
}

@media screen and (max-width: 540px) {
  .popup__container {
    max-width: 282px;
    padding: 25px 22px;
  }
}