.sign-up__description {
  margin: 0;
  margin-top: 15px;
  margin-bottom: 80px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  text-align: center;
}

@media screen and (max-width: 540px) {
  .sign-up__description {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
