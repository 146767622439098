@font-face {
  src: url('./Inter/__black/Inter-Black.ttf') format('truetype'),
      url('./Inter/__black/Inter-Black.woff') format('woff'),
      url('./Inter/__black/Inter-Black.woff2') format('woff2');
  font-family: 'Inter';
  font-weight: 900;
}

@font-face {
  src: url('./Inter/__regular/Inter-Regular.ttf') format('truetype'),
      url('./Inter/__regular/Inter-Regular.woff') format('woff'),
      url('./Inter/__regular/Inter-Regular.woff2') format('woff2');
  font-family: 'Inter';
  font-weight: 400;
}

@font-face {
  src: url('./Inter/__medium/Inter-Medium.ttf') format('truetype'),
      url('./Inter/__medium/Inter-Medium.woff') format('woff'),
      url('./Inter/__medium/Inter-Medium.woff2') format('woff2');
  font-family: 'Inter';
  font-weight: 500;
}