.popup {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, .5);
  min-height: 100%;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s, opacity 1s;
  z-index: 1;
}