.button_type_card {
  border: 0;
  width: 100%;
  height: 282px;
  overflow: hidden;
  object-fit: cover;
}

.button_type_card:hover {
  opacity: 1;
}